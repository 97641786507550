<template>
  <div id="dashboard">
    <vue-headful
      title="Dashboard | Octoa"
    />
    <modal
      name="createProject"
      class="modal overflow-visible"
      width="100%"
      :max-width="700"
      height="auto"
      :adaptive="true"
    >
      <div class="modal-content-wrapper">
        <div class="flex px-2 justify-between">
          <div class="title">
            Create a new project
          </div>
          <div
            class="close "
            @click="$modal.hide('createProject')"
          />
        </div>

        <div class="flex px-2 mt-2">
          <div class="input-group w-full">
            <label>Give your project a title</label>
            <input
              v-model="projectName"
              v-validate="{ required: true }"
              type="text"
              name="projectName"
              class="w-full"
            >
            <span
              v-if="errors.has('projectName')"
              class="error"
            >A project name is required.</span>
          </div>
        </div>

        <div class="flex flex-wrap mt-2 justify-between">
          <div
            class="w-full md:w-2/3 px-2"
          >
            <div class="w-full input-group block">
              <label v-if="!isNewContact">Search for a contact</label>
              <autocomplete
                v-if="!isNewContact"
                :search="searchContact"
                :get-result-value="getContactResult"
                :debounce-time="500"
                @submit="searchContactClick"
              />
              <span
                v-if="hasError && !isNewContact && !selectedContact"
                class="error"
              >Please search and select an existing contact.</span>
              <div v-if="isNewContact">
                <div class="input-group">
                  <label class="text-base">Client's full name</label>
                  <input
                    v-model="fullName"
                    v-validate="{ required: true }"
                    type="text"
                    class="w-full"
                    name="Full name"
                  >
                  <span
                    v-if="errors.has('Full name')"
                    class="error"
                  >{{ errors.first('Full name') }}.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-2">
            <a
              class="md:mt-10 green underline cursor-pointer mt-2 mb-3 md:mb-0 md:ml-3 block"
              @click="toggleContact"
            >
              <span v-if="!isNewContact">Add a new contact</span>
              <span v-if="isNewContact">Search a contact by name</span>
            </a>
          </div>
        </div>
        <div
          v-if="isNewContact"
          class="flex mt-2 justify-between items-center"
        >
          <div class="w-full px-2">
            <div class="input-group">
              <label>Client's email</label>
              <input
                v-model.lazy.trim="email"
                v-validate="{ required: true, email: true }"
                type="text"
                class="w-full"
                name="email"
                @blur="checkEmail"
              >
              <span
                v-if="errors.has('email')"
                class="error"
              >{{ errors.first('email') }}.</span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-2 items-top">
          <div class="w-full md:w-1/2 px-2">
            <div class="input-group">
              <div class="flex justify-between items-center">
                <label>Project date</label>
                <div class="flex items-center">
                  <v-checkbox
                    id="multi-days"
                    v-model="multiDays"
                    name="multi-days"
                    :checked="multiDays"
                    label="Multi-day"
                  />
                </div>
              </div>
              <v-date-picker
                v-if="!multiDays"
                v-model="projectDate"
                class="datepicker"
                :popover="{ visibility: 'click', placement: 'top' }"
                :masks="{L: 'WWW, MMM D, YYYY'}"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                  >
                </template>
              </v-date-picker>
              <div
                v-if="multiDays"
                class="flex"
              >
                <v-date-picker
                  v-model="projectDate"
                  class="datepicker mr-2"
                  :popover="{ visibility: 'click', placement: 'top' }"
                  :masks="{L: 'WWW, MMM D, YYYY'}"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      placeholder="Start date"
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                  </template>
                </v-date-picker>
                <v-date-picker
                  v-model="projectEndDate"
                  class="datepicker"
                  :popover="{ visibility: 'click', placement: 'top' }"
                  :masks="{L: 'WWW, MMM D, YYYY'}"
                  :min-date="projectDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      placeholder="End date"
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                  </template>
                </v-date-picker>
              </div>
              <span
                v-if="projectDateError"
                class="error"
              >
                {{ projectDateError }}
              </span>
            </div>
          </div>
          <div class="w-full md:w-1/2 px-2 mt-3 md:mt-0">
            <div class="input-group block">
              <label>Add tags (comma separated)</label>
              <vue-tags-input
                v-model="tag"
                placeholder=""
                :tags="tags"
                :autocomplete-items="autocompleteTagItems"
                :add-on-key="[188]"
                @tags-changed="updateTags"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap items-center mt-2">
          <div class="w-1/2 px-2">
            <div
              class="input-group block"
            >
              <label>Choose workflow <v-help-tooltip
                class="absolute"
                :options="workflowContent"
              /></label>
              <div class="select-container w-full">
                <select
                  v-model="selectedWorkflow"
                  v-validate="{ required: true }"
                  name="selectedWorkflow"
                >
                  <option :value="null">Select a workflow</option>
                  <option
                    v-for="workflow in workflows"
                    :key="workflow.id"
                    :value="workflow.id"
                  >
                    {{ workflow.name }}
                  </option>
                </select>
                <span
                  v-if="errors.has('selectedWorkflow')"
                  class="error"
                >A workflow is required.</span>
              </div>
            </div>
          </div>
          <div class="flex w-1/2 items-center flex-wrap">
            <div
              class="px-2"
              :class="{
                'w-1/2': multiCurrencies === 1,
                'w-full': multiCurrencies !== 1
              }"
            >
              <div
                class="input-group block w1/2"
              >
                <label>Language <v-help-tooltip
                  class="absolute"
                  :options="languageContent"
                /></label>
                <div class="select-container w-full">
                  <select v-model="projectLanguage">
                    <option
                      v-for="language in createProjectLanguages"
                      :key="language.code"
                      :value="language.code"
                    >
                      {{ language.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="w-1/2 px-2">
              <div
                v-show="multiCurrencies == 1"
                class="input-group block"
              >
                <label>Currency</label>
                <div class="select-container w-full">
                  <select v-model="projectCurrency">
                    <option
                      v-for="currency in currencies"
                      :key="currency.id"
                      :value="currency.id"
                    >
                      {{ currency.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center mt-2"
        >
          <div class="w-1/2 px-2 " />
          <div class="w-1/2 px-2 text-center md:text-right mb-10">
            <button
              class="green-btn w-full"
              @click="createProject"
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </modal>
    <div
      class="mb-10 md:pb-0"
    >
      <div>
        <div class="lg:mt-6 mt-4 px-4 mx-auto max-w-2xl">
          <div class="mt-1 md:mt-3 lg:mt-6 flex flex-wrap items-center justify-end">
            <div
              class="flex items-center green-plus-btn"
              @click="showCreateProjectModal"
            >
              <div class="plus">
                <img
                  src="@/assets/img/icons/ico-plus.svg"
                  class="align-middle"
                >
              </div>
              <div class="flex-grow">
                <button>
                  Create new project
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="progress != 'done'"
            class="hidden md:block bg-white pt-8 pb-5 my-5 px-5 border border-solid rounded border-grey-ea"
          >
            <div class="max-w-lg mx-auto">
              <h1 class="text-center mb-5 text-xl">
                Get started
              </h1>
              <div
                class="progress-line mb-6"
                :class="progress"
              />
              <div class="progress flex flex-grow justify-between items-center">
                <div
                  class="state brand left"
                  :class="{'active' : progress == 'brand' || progress == 'info' || progress == 'finish' }"
                >
                  <div class="dot" />
                  <div
                    class="text"
                    :class="{'active' : progress == 'brand'}"
                  >
                    1. Add brand logo
                  </div>
                </div>
                <div
                  class="state info center"
                  :class="{'active' : progress == 'info' || progress == 'finish' }"
                >
                  <div class="dot" />
                  <div
                    class="text"
                    :class="{'active' : progress == 'info'}"
                  >
                    2. Proposal and invoice information
                  </div>
                </div>
                <div
                  class="state finish right "
                  :class="{'active' : progress == 'finish' }"
                >
                  <div class="dot" />
                  <div
                    class="text"
                    :class="{'active' : progress == 'finish'}"
                  >
                    3. Test invoice
                  </div>
                </div>
              </div>

              <div class="demo-container pt-5 px-5 mt-8 rounded">
                <div
                  v-show="progress == 'finish'"
                  class="demo-finish"
                >
                  <div>
                    <h1>We created a test invoice for you!</h1>
                    <p>Go try it out!</p>
                    <button
                      class="green-btn"
                      @click="goTestInvoice"
                    >
                      <span class="px-5">
                        View my invoice
                      </span>
                    </button>
                  </div>
                </div>
                <div class="demo-header flex">
                  <div class="w-6/12 text-left">
                    <div
                      class="image-upload"
                      :class="{'no-bg' : logo.path != '' }"
                      @click="$refs.file.click()"
                    >
                      <input
                        ref="file"
                        type="file"
                        style="display:none;"
                        @change="fileUpload"
                      >
                      <img
                        v-show="!uploadLoading"
                        v-if="logo.path"
                        :src="logo.path"
                        :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '110px', 'height':'110px'} : '' ]"
                      >
                      <div
                        v-show="uploadLoading"
                        class="px-3 py-3"
                      >
                        <img
                          src="@/assets/img/icons/loader.svg"
                          width="40"
                          class="mx-4 my-4 bg-white"
                        >
                      </div>
                    </div>
                    <div
                      v-show="progress == 'brand'"
                      class="floating-tag"
                    >
                      <div class="tip" />
                      <span class="underline">Upload your logo</span> preferably minimum size 500px width or 500px height with extension JPG or PNG.
                    </div>
                    <div class="mt-10 flex items-center ">
                      <div class="w-3/12 mr-2 text-grey-semi-light">
                        Quote
                      </div>
                      <div class="w-9/12 font-bold">
                        001
                      </div>
                    </div>
                    <div class="my-3 flex items-center ">
                      <div class="w-3/12 mr-2 text-grey-semi-light">
                        Issued
                      </div>
                      <div class="w-9/12 ">
                        {{ today }}
                      </div>
                    </div>
                    <div class="my-3 flex items-center ">
                      <div class="w-3/12 mr-2 text-grey-semi-light">
                        Valid until
                      </div>
                      <div class="w-9/12 ">
                        {{ next2Week }}
                      </div>
                    </div>
                  </div>
                  <div class="w-6/12 flex flex-wrap text-left">
                    <div class="flex w-full leading-loosen">
                      <div class="w-4/12 text-grey-semi-light">
                        <div class="ml-8">
                          From
                        </div>
                      </div>
                      <div class="w-8/12 text-grey-darker">
                        <div class="ml-4">
                          <strong class="text-black">{{ user.company.name }}</strong> <br>
                          {{ user.company.address }} <br>
                          <pre
                            v-if="user.company.city"
                            style="font-family:inherit"
                          >{{ user.company.city }}<span v-if="user.company.postal_code">,</span> {{ user.company.postal_code }}</pre>
                          {{ user.company.country }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="my-4 flex w-full leading-loosen"
                    >
                      <div class="w-4/12 text-grey-semi-light">
                        &nbsp;
                        <div
                          v-show="progress == 'info'"
                          class="floating-tag info"
                        >
                          <div class="tip" />
                          Add extra information e.g. company id and bank details
                        </div>
                      </div>
                      <div class="w-8/12 text-grey-darker">
                        <textarea
                          v-show="progress == 'info'"
                          v-model="paymentInfo"
                          rows="5"
                          placeholder="Bank: NL49RABO5004293872
KVK: 328749876
VAT: 283648736"
                        />
                        <div
                          v-show="progress == 'finish'"
                          class="ml-4"
                        >
                          <pre>{{ paymentInfo }}</pre>
                        </div>
                      </div>
                    </div>
                    <div class="flex w-full leading-loosen">
                      <div class="w-4/12 text-grey-semi-light">
                        <div class="ml-8">
                          To
                        </div>
                      </div>
                      <div class="w-8/12 text-grey-darker">
                        <div class="ml-4">
                          Christine McKinsey <br>
                          Jansweg 32 <br>
                          Haarlem, 2011RN <br>
                          Netherlands
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="progress == 'info'"
                class="mt-4 block text-right"
              >
                <button
                  class="green-btn"
                  @click="saveBankDetails"
                >
                  <span class="px-5">
                    Next
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="hidden md:flex flex-wrap md:mt-3">
            <div class="w-full flex items-center justify-between md:w-5/12">
              <div>
                <h2 class="font-bold">
                  Needs your attention
                </h2>
              </div>
              <div
                class="mr-3 pb-2 text-xs cursor-pointer"
                @click="markAllAsSeen"
              >
                <img
                  src="@/assets/img/icons/ico-preview.svg"
                  width="16"
                  class="mr-1"
                > Mark all as seen
              </div>
            </div>
            <div class="w-full md:w-7/12 md:h-full flex justify-between items-center">
              <h2 class="ml-0 md:ml-3 font-bold">
                Upcoming tasks
              </h2>
              <router-link
                class="text-xs text-blue-6c underline"
                to="/tasks"
              >
                View all tasks
              </router-link>
            </div>
          </div>

          <div class="flex flex-wrap md:h-full mt-10 md:mt-1">
            <div class="w-full h-auto md:w-5/12">
              <div class="h-full">
                <div class="flex md:hidden items-center justify-between">
                  <div>
                    <h2 class="font-bold">
                      Needs your attention
                    </h2>
                  </div>
                  <div
                    class="pb-2 text-xs cursor-pointer"
                    @click="markAllAsSeen"
                  >
                    <img
                      src="@/assets/img/icons/ico-preview.svg"
                      width="16"
                      class="mr-1"
                    > Mark all as seen
                  </div>
                </div>

                <div class="card md:h-full md:mr-3">
                  <div
                    v-show="activityLoading"
                    class="flex h-full pt-10 pb-12 md:py-0 text-center items-center"
                  >
                    <div class="mx-auto">
                      <v-spinner :show="activityLoading" />
                    </div>
                  </div>

                  <div
                    v-show="!activityLoading"
                    :class="{ 'h-full' : activities.length == 0}"
                  >
                    <div
                      v-if="activities.length > 0"
                      class="overflow-scroll-y"
                    >
                      <a
                        v-for="(activity, index) in activities"
                        :key="activity.id"
                        class="item click no-border"
                        :class="{ 'seen': activity.has_seen, 'due': activity.action == 'due' }"
                        @click="markAsSeen(activity)"
                      >
                        <div class="line flex">
                          <div class="w-5 mr-4">
                            <div
                              v-if="activity.type == 'proposal'"
                              class="icon"
                            >
                              <svg
                                width="18px"
                                height="19px"
                                viewBox="0 0 18 19"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="04---Desktop-Pages"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="Octoa-Dashboard"
                                    transform="translate(-174.000000, -317.000000)"
                                    fill="#B1B6CD"
                                    fill-rule="nonzero"
                                  >
                                    <g
                                      id="Group"
                                      transform="translate(150.000000, 297.000000)"
                                    >
                                      <g
                                        id="icons/proposal-copy-3"
                                        transform="translate(1.000000, 15.000000)"
                                      >
                                        <g
                                          id="pencil-write-2-alternate"
                                          transform="translate(0.000000, 0.278571)"
                                        >
                                          <g
                                            id="attention_01"
                                            transform="translate(0.000000, 0.002785)"
                                          >
                                            <g
                                              id="Group-24"
                                              transform="translate(23.000000, 5.000000)"
                                            >
                                              <path
                                                id="Path"
                                                d="M14.7891892,8.5009985 C14.380797,8.5009985 14.0497297,8.83206578 14.0497297,9.24045796 L14.0497297,15.5258634 C14.0497297,15.9342555 13.7186625,16.2653228 13.3102703,16.2653228 L2.21837838,16.2653228 C1.8099862,16.2653228 1.47891892,15.9342555 1.47891892,15.5258634 L1.47891892,2.95505255 C1.47891892,2.54666037 1.8099862,2.2155931 2.21837838,2.2155931 L9.61297297,2.2155931 C10.0213652,2.2155931 10.3524324,1.88452582 10.3524324,1.47613364 C10.3524324,1.06774145 10.0213652,0.736674176 9.61297297,0.736674176 L2.21837838,0.736674176 C0.993201831,0.736674176 -5.72875081e-13,1.72987601 -5.72875081e-13,2.95505255 L-5.72875081e-13,15.5258634 C-5.72875081e-13,16.7510399 0.993201831,17.7442417 2.21837838,17.7442417 L13.3102703,17.7442417 C14.5354468,17.7442417 15.5286486,16.7510399 15.5286486,15.5258634 L15.5286486,9.24045796 C15.5286486,8.83206578 15.1975814,8.5009985 14.7891892,8.5009985 Z"
                                              />
                                              <path
                                                id="Path"
                                                d="M17.3144432,0.427580122 C16.7339476,-0.142526707 15.8037475,-0.142526707 15.2232519,0.427580122 L7.61643243,8.0358785 C7.58093108,8.0712936 7.55300488,8.11356027 7.53435243,8.16010769 L6.48653838,10.7740969 C6.43174948,10.9114144 6.46403112,11.0681694 6.56861838,11.1726655 C6.67379628,11.2759344 6.82957526,11.3083041 6.96718703,11.255485 L9.58191568,10.2091499 C9.62834107,10.1902652 9.67056401,10.1623679 9.70614486,10.1270699 L17.3144432,2.51877147 C17.8917865,1.94125386 17.8917865,1.00509774 17.3144432,0.427580122 Z"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div
                              v-if="activity.type == 'invoice'"
                              class="red icon"
                            >
                              <svg
                                width="17px"
                                height="22px"
                                viewBox="0 0 17 22"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="04---Desktop-Pages"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="Octoa-Dashboard"
                                    transform="translate(-173.000000, -742.000000)"
                                    fill="#B1B6CD"
                                  >
                                    <g
                                      id="Group"
                                      transform="translate(150.000000, 297.000000)"
                                    >
                                      <g
                                        id="icons/proposal-copy-3"
                                        transform="translate(1.000000, 15.000000)"
                                      >
                                        <g
                                          id="pencil-write-2-alternate"
                                          transform="translate(0.000000, 0.278571)"
                                        >
                                          <g
                                            id="attention_01-copy-5"
                                            transform="translate(0.000000, 425.002785)"
                                          >
                                            <g
                                              id="Group-2"
                                              transform="translate(0.000000, 5.000000)"
                                            >
                                              <g
                                                id="accounting-invoice"
                                                transform="translate(22.000000, 0.000000)"
                                              >
                                                <path
                                                  id="Shape"
                                                  d="M16.3690678,4.10828571 L12.5339657,0.251142857 C12.374176,0.0903862502 12.1574395,0 11.9314286,0 L2.13061224,0 C1.18924852,0 0.426122449,0.767511857 0.426122449,1.71428571 L0.426122449,18.8571429 C0.426122449,19.8039167 1.18924852,20.5714286 2.13061224,20.5714286 L14.9142857,20.5714286 C15.8556494,20.5714286 16.6187755,19.8039167 16.6187755,18.8571429 L16.6187755,4.71428571 C16.6187755,4.48697587 16.5289058,4.26899377 16.3690678,4.10828571 Z M14.0620408,18.8571429 L2.98285714,18.8571429 C2.51217528,18.8571429 2.13061224,18.4733869 2.13061224,18 L2.13061224,2.57142857 C2.13061224,2.09804164 2.51217528,1.71428571 2.98285714,1.71428571 L11.2257698,1.71428571 C11.4517807,1.71428571 11.6685172,1.80467196 11.8283069,1.96542857 L14.664578,4.818 C14.824416,4.97870806 14.9142857,5.19669016 14.9142857,5.424 L14.9142857,18 C14.9142857,18.4733869 14.5327227,18.8571429 14.0620408,18.8571429 Z"
                                                />
                                                <path
                                                  id="Path"
                                                  d="M8.81563304,8.49257143 C8.81843022,7.67075835 8.3201961,6.93118873 7.56026449,6.62914286 L5.80208327,5.922 C5.67298931,5.8694319 5.59891068,5.73235403 5.62526604,5.59481077 C5.65162139,5.45726751 5.77104575,5.35769513 5.91031837,5.35714286 L7.64378449,5.35714286 C8.11446635,5.35714286 8.49602939,4.97338693 8.49602939,4.5 C8.49602939,4.02661307 8.11446635,3.64285714 7.64378449,3.64285714 L7.43072327,3.64285714 C7.3130528,3.64285714 7.21766204,3.54691816 7.21766204,3.42857143 C7.21766204,2.9551845 6.836099,2.57142857 6.36541714,2.57142857 C5.89473528,2.57142857 5.51317224,2.9551845 5.51317224,3.42857143 L5.51317224,3.51428571 C5.5127785,3.60966646 5.44999001,3.69340039 5.35891592,3.72 C4.53939127,3.95707735 3.96014266,4.69160679 3.91722228,5.54817254 C3.87430191,6.40473829 4.37719133,7.19411601 5.16886531,7.51285714 L6.92789878,8.22 C7.05848687,8.27196294 7.1336639,8.41019482 7.10672455,8.54881617 C7.0797852,8.68743753 6.95839038,8.78702774 6.81795918,8.78571429 L5.0870498,8.78571429 C4.61636794,8.78571429 4.2348049,9.16947021 4.2348049,9.64285714 C4.2348049,10.1162441 4.61636794,10.5 5.0870498,10.5 L5.30011102,10.5 C5.41778149,10.5 5.51317224,10.595939 5.51317224,10.7142857 C5.51317224,11.1876726 5.89473528,11.5714286 6.36541714,11.5714286 C6.836099,11.5714286 7.21766204,11.1876726 7.21766204,10.7142857 L7.21766204,10.6285714 C7.21728222,10.5330161 7.2798562,10.448755 7.37106612,10.422 C8.22610477,10.1742117 8.81510411,9.38751128 8.81563304,8.49257143 Z"
                                                />
                                                <path
                                                  id="Path"
                                                  d="M3.40897959,13.2857143 L3.40897959,14.1428571 C3.40897959,14.3795506 3.59976111,14.5714286 3.83510204,14.5714286 L6.60489796,14.5714286 C6.72256842,14.5714286 6.81795918,14.4754896 6.81795918,14.3571429 L6.81795918,13.0714286 C6.81795918,12.9530818 6.72256842,12.8571429 6.60489796,12.8571429 L3.83510204,12.8571429 C3.59976111,12.8571429 3.40897959,13.0490208 3.40897959,13.2857143 Z"
                                                />
                                                <path
                                                  id="Path"
                                                  d="M3.40897959,15.8571429 L3.40897959,16.7142857 C3.40897959,16.9509792 3.59976111,17.1428571 3.83510204,17.1428571 L6.60489796,17.1428571 C6.72256842,17.1428571 6.81795918,17.0469182 6.81795918,16.9285714 L6.81795918,15.6428571 C6.81795918,15.5245104 6.72256842,15.4285714 6.60489796,15.4285714 L3.83510204,15.4285714 C3.59976111,15.4285714 3.40897959,15.6204494 3.40897959,15.8571429 Z"
                                                />
                                                <path
                                                  id="Path"
                                                  d="M7.67020408,14.3571429 C7.67020408,14.4754896 7.76559484,14.5714286 7.88326531,14.5714286 L12.7836735,14.5714286 C13.0190144,14.5714286 13.2097959,14.3795506 13.2097959,14.1428571 L13.2097959,13.2857143 C13.2097959,13.0490208 13.0190144,12.8571429 12.7836735,12.8571429 L7.88326531,12.8571429 C7.76559484,12.8571429 7.67020408,12.9530818 7.67020408,13.0714286 L7.67020408,14.3571429 Z"
                                                />
                                                <path
                                                  id="Path"
                                                  d="M12.7836735,15.4285714 L7.88326531,15.4285714 C7.76559484,15.4285714 7.67020408,15.5245104 7.67020408,15.6428571 L7.67020408,16.9285714 C7.67020408,17.0469182 7.76559484,17.1428571 7.88326531,17.1428571 L12.7836735,17.1428571 C13.0190144,17.1428571 13.2097959,16.9509792 13.2097959,16.7142857 L13.2097959,15.8571429 C13.2097959,15.6204494 13.0190144,15.4285714 12.7836735,15.4285714 Z"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div
                              v-if="activity.type == 'lead'"
                              class="icon -mt-2"
                            >
                              <svg
                                style="width:20px;"
                                width="35px"
                                height="38px"
                                viewBox="0 0 35 38"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="icons/leadform"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="task-checklist"
                                    transform="translate(3.000000, 0.000000)"
                                    fill="#FAB413"
                                    fill-rule="nonzero"
                                  >
                                    <path
                                      id="Path"
                                      d="M21.75,14.1142857 L8.25,14.1142857 C7.62867966,14.1142857 7.125,14.6246811 7.125,15.2542857 C7.125,15.8838903 7.62867966,16.3942857 8.25,16.3942857 L21.75,16.3942857 C22.3713203,16.3942857 22.875,15.8838903 22.875,15.2542857 C22.875,14.6246811 22.3713203,14.1142857 21.75,14.1142857 Z"
                                    />
                                    <path
                                      id="Path"
                                      d="M22.875,20.5727657 C22.875,19.9431611 22.3713203,19.4327657 21.75,19.4327657 L8.25,19.4327657 C7.62867966,19.4327657 7.125,19.9431611 7.125,20.5727657 C7.125,21.2023703 7.62867966,21.7127657 8.25,21.7127657 L21.75,21.7127657 C22.3713203,21.7127657 22.875,21.2023703 22.875,20.5727657 L22.875,20.5727657 Z"
                                    />
                                    <path
                                      id="Shape"
                                      d="M12,10.3142857 L18,10.3142857 C18.8284271,10.3142857 19.5,9.63375853 19.5,8.79428571 L19.5,4.99428571 C19.497496,3.56845765 18.838498,2.2254937 17.7180895,1.36295175 C16.597681,0.500409801 15.1442293,0.217112985 13.788,0.596925714 C11.8087802,1.181799 10.4615682,3.03773921 10.5,5.12652571 L10.5,8.79428571 C10.5,9.63375853 11.1715729,10.3142857 12,10.3142857 Z M13.875,4.61428571 C13.875,3.9846811 14.3786797,3.47428571 15,3.47428571 C15.6213203,3.47428571 16.125,3.9846811 16.125,4.61428571 C16.125,5.24389033 15.6213203,5.75428571 15,5.75428571 C14.7013714,5.75428598 14.4149957,5.63397056 14.2039741,5.41985022 C13.9929525,5.20572989 13.8746018,4.91537578 13.875,4.61276571 L13.875,4.61428571 Z"
                                    />
                                    <path
                                      id="Path"
                                      d="M26.25,4.99428571 L22.125,4.99428571 C21.9178932,4.99428571 21.75,5.16441751 21.75,5.37428571 L21.75,7.65428571 C21.75,7.86415392 21.9178932,8.03428571 22.125,8.03428571 L25.5,8.03428571 C25.9142136,8.03428571 26.25,8.3745493 26.25,8.79428571 L26.25,28.2381257 C26.2503555,28.439828 26.1715709,28.6334006 26.031,28.7762057 L21.219,33.6508457 C21.0784417,33.7929185 20.8880287,33.872723 20.6895,33.8727657 L4.5,33.8727657 C4.08578644,33.8727657 3.75,33.5325021 3.75,33.1127657 L3.75,8.79428571 C3.75,8.3745493 4.08578644,8.03428571 4.5,8.03428571 L7.875,8.03428571 C8.08210678,8.03428571 8.25,7.86415392 8.25,7.65428571 L8.25,5.37428571 C8.25,5.16441751 8.08210678,4.99428571 7.875,4.99428571 L3.75,4.99428571 C2.09314575,4.99428571 0.75,6.35534007 0.75,8.03428571 L0.75,33.8742857 C0.75,35.5532314 2.09314575,36.9142857 3.75,36.9142857 L26.25,36.9142857 C27.9068542,36.9142857 29.25,35.5532314 29.25,33.8742857 L29.25,8.03428571 C29.25,6.35534007 27.9068542,4.99428571 26.25,4.99428571 Z"
                                    />
                                    <path
                                      id="Path"
                                      d="M8.25,24.7542857 C7.62867966,24.7542857 7.125,25.2646811 7.125,25.8942857 C7.125,26.5238903 7.62867966,27.0342857 8.25,27.0342857 L13.875,27.0342857 C14.4963203,27.0342857 15,26.5238903 15,25.8942857 C15,25.2646811 14.4963203,24.7542857 13.875,24.7542857 L8.25,24.7542857 Z"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div
                              v-if="activity.type == 'questionnaire'"
                              class="icon"
                            >
                              <svg
                                width="19px"
                                height="20px"
                                viewBox="0 0 19 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="04---Desktop-Pages"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="Octoa-Dashboard"
                                    transform="translate(-173.000000, -405.000000)"
                                    fill="#B1B6CD"
                                    fill-rule="nonzero"
                                  >
                                    <g
                                      id="Group"
                                      transform="translate(150.000000, 297.000000)"
                                    >
                                      <g
                                        id="icons/proposal-copy-3"
                                        transform="translate(1.000000, 15.000000)"
                                      >
                                        <g
                                          id="pencil-write-2-alternate"
                                          transform="translate(0.000000, 0.278571)"
                                        >
                                          <g
                                            id="attention_01-copy"
                                            transform="translate(0.000000, 85.002785)"
                                          >
                                            <g
                                              id="question-help-message-alternate"
                                              transform="translate(22.000000, 8.000000)"
                                            >
                                              <path
                                                id="Shape"
                                                d="M16.2291667,0 L2.77083333,0 C1.24054434,0 -7.69844462e-09,1.24054434 -7.69844462e-09,2.77083333 L-7.69844462e-09,18.2083333 C-7.69844462e-09,18.5060884 0.166991831,18.7786851 0.432302094,18.9138463 C0.697612357,19.0490075 1.01632422,19.0238717 1.25716667,18.8487917 L5.350875,15.8713333 C5.38475005,15.8468315 5.42544311,15.8335439 5.46725,15.8333333 L16.2291667,15.8333333 C17.7594557,15.8333333 19,14.592789 19,13.0625 L19,2.77083333 C19,1.24054434 17.7594557,0 16.2291667,0 Z M17.4166667,13.0625 C17.4166667,13.7183381 16.8850048,14.25 16.2291667,14.25 L5.14583333,14.25 C4.97859017,14.249968 4.81563305,14.3029014 4.68033333,14.4012083 L1.9,16.4247083 C1.84014775,16.4689807 1.76034841,16.4754261 1.69416667,16.4413333 C1.62684413,16.4081423 1.58396929,16.3398488 1.58333333,16.2647917 L1.58333333,2.77083333 C1.58333333,2.11499519 2.11499519,1.58333333 2.77083333,1.58333333 L16.2291667,1.58333333 C16.8850048,1.58333333 17.4166667,2.11499519 17.4166667,2.77083333 L17.4166667,13.0625 Z"
                                              />
                                              <circle
                                                id="Oval"
                                                cx="9.5"
                                                cy="12.2708333"
                                                r="1.1875"
                                              />
                                              <path
                                                id="Path"
                                                d="M9.5,2.57608333 C7.75109829,2.57608333 6.33333333,3.99384829 6.33333333,5.74275 C6.33333333,6.17997543 6.68777457,6.53441667 7.125,6.53441667 C7.56222543,6.53441667 7.91666667,6.17997543 7.91666667,5.74275 C7.91665469,4.95195827 8.50011276,4.28241048 9.2834752,4.17426773 C10.0668376,4.06612497 10.8098772,4.55255051 11.0241192,5.31376789 C11.2383612,6.07498528 10.8581059,6.87755831 10.1333333,7.193875 C9.2677001,7.5716683 8.70819022,8.42647506 8.70833333,9.37095833 C8.70833333,9.80818376 9.06277457,10.162625 9.5,10.162625 C9.93722543,10.162625 10.2916667,9.80818376 10.2916667,9.37095833 C10.291514,9.0560348 10.4780344,8.77096939 10.7666667,8.645 C12.140957,8.0451486 12.9061235,6.5636326 12.5997591,5.09576456 C12.2933948,3.62789653 10.9994985,2.57608333 9.5,2.57608333 Z"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div
                              v-if="activity.type == 'task'"
                              class="red icon"
                            >
                              <svg
                                width="19px"
                                height="19px"
                                viewBox="0 0 19 19"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="Symbols"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="attention_05"
                                    transform="translate(-20.000000, -5.000000)"
                                    fill="#B1B6CD"
                                    fill-rule="nonzero"
                                  >
                                    <g
                                      id="check-2-alternate"
                                      transform="translate(20.000000, 5.000000)"
                                    >
                                      <path
                                        id="Path"
                                        d="M14.836625,4.942375 C14.6253078,4.78665718 14.3607803,4.72127731 14.1012519,4.76062229 C13.8417235,4.79996727 13.6084602,4.94081314 13.4527917,5.15216667 L8.52229167,11.844125 L5.3675,9.32029167 C5.09232099,9.09021272 4.71418356,9.02705295 4.37916855,9.15521204 C4.04415354,9.28337113 3.80471664,9.58278229 3.75335635,9.93777797 C3.70199606,10.2927736 3.84675423,10.6477696 4.13170833,10.865625 L8.09004167,14.0322917 C8.26558659,14.1721406 8.48390209,14.247335 8.70833333,14.2452922 C9.02136541,14.2462478 9.31653199,14.0995447 9.50475,13.8494167 L15.0464167,6.32858333 C15.203094,6.11717816 15.2690452,5.8520422 15.2296702,5.59187004 C15.1902952,5.33169789 15.048842,5.09795093 14.836625,4.942375 Z"
                                      />
                                      <path
                                        id="Shape"
                                        d="M17.4166667,0 L1.58333333,0 C0.708882479,0 0,0.708882479 0,1.58333333 L0,17.4166667 C0,18.2911175 0.708882479,19 1.58333333,19 L17.4166667,19 C18.2911175,19 19,18.2911175 19,17.4166667 L19,1.58333333 C19,0.708882479 18.2911175,0 17.4166667,0 Z M17.4166667,17.21875 C17.4166667,17.3280564 17.3280564,17.4166683 17.21875,17.4166683 L1.78125,17.4166683 C1.72869469,17.4168782 1.67823083,17.3960942 1.64106831,17.3589317 C1.60390579,17.3217692 1.58312184,17.2713053 1.58333173,17.21875 L1.58333173,1.78045833 C1.58333173,1.72804008 1.60421002,1.67778021 1.64134952,1.64078926 C1.67848903,1.60379831 1.72883217,1.58312282 1.78125,1.58333174 L17.21875,1.58333174 C17.3280564,1.58333174 17.4166667,1.67194364 17.4166667,1.78125 L17.4166667,17.21875 Z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                          <div clas="w-11/12">
                            <div>
                              <div v-if="activity.type != 'lead'">
                                <span class="type">{{ activity.type | toUpperCaseFormat }} {{ activity.action }}</span>
                                <span
                                  v-if="activity.type != 'task' && activity.project"
                                  class="text-xs ml-1"
                                >for {{ activity.project.name }}</span>
                                <span
                                  v-if="activity.type == 'task'"
                                  class="text-xs ml-1"
                                >for {{ activity.name }}</span>
                              </div>
                              <div v-else>
                                <span class="type">New inquiry</span>
                                <span
                                  v-if="activity.project"
                                  class="text-xs ml-1"
                                >from {{ activity.project.name }}</span>
                              </div>
                            </div>
                            <div class="mt-2 text-grey-semi-light text-xs">
                              {{ activity.created_at | humanDateWithTimeStamp(timezone) }}
                            </div>
                          </div>
                        </div>
                      </a>
                      <div
                        v-if="activityLoadMore"
                        class="more-activities click item"
                        @click="showMoreActivities"
                      >
                        <div v-show="!activityLoadMoreLoading">
                          More activities <img
                            src="@/assets/img/icons/ico-arrow-down-green.svg"
                            width="14"
                            class="align-bottom"
                          >
                        </div>
                        <div v-show="activityLoadMoreLoading">
                          <img
                            src="@/assets/img/icons/loader.svg"
                            width="40"
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="flex h-full pt-10 pb-12 md:py-0 text-center items-center"
                    >
                      <div class="mx-auto">
                        <div class="mb-1">
                          <img src="@/assets/img/icons/ico-alert-circle-blue-washed.svg">
                        </div>
                        You have no active activity.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-12 md:mt-0 w-full md:w-7/12 md:h-full">
              <div v-show="tasksLoading">
                <div class="card ml-0 md:ml-3 text-center">
                  <div class="empty">
                    <v-spinner :show="tasksLoading" />
                  </div>
                </div>
              </div>
              <div v-show="!tasksLoading">
                <div v-if="groupedTasks.size > 0">
                  <div class="flex md:hidden justify-between items-center">
                    <h2 class="font-bold">
                      Upcoming tasks
                    </h2>
                    <router-link
                      class="text-xs text-blue-6c underline"
                      to="/tasks"
                    >
                      View all tasks
                    </router-link>
                  </div>

                  <div class="md:ml-3 mb-8">
                    <div
                      v-for="[key, value] in groupedTasks"
                      :key="key"
                      class="flex bg-white border border-grey-lighter rounded mb-4"
                    >
                      <div class="ml-4 mt-6 w-24">
                        <due-badge
                          :date="new Date(key)"
                        />
                      </div>
                      <div class="flex-1 min-w-0 mx-4 mb-0">
                        <div
                          v-for="(item, index) in value"
                          :key="item.id"
                          class="flex justify-between py-4 relative"
                          :class="{
                            'border-b border-grey-lighter': value.length > 1 && index < (value.length - 1)
                          }"
                        >
                          <div class="w-2/3 mr-4 h-full">
                            <div class="mb-1">{{ item.name }}</div>
                            <div class="text-xs">
                              <router-link
                                class="text-grey-semi-light"
                                :to="`/projects/${item.project.id}`"
                              >
                                {{ item.project.name }}
                              </router-link>
                            </div>
                          </div>
                          <div class="w-1/3 flex flex-col flex-grow">
                            <div class="mb-3 capitalize text-xs">{{ item.project.state }}</div>
                            <action-progress :actions="item.project.phase_actions" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="card ml-0 md:ml-3 text-center">
                    <div class="empty">
                      <div class="mb-1">
                        <img src="@/assets/img/icons/ico-alert-circle-blue-washed.svg">
                      </div>
                      You have no upcoming tasks.
                    </div>
                  </div>
                </div>
              </div>


              <h2 class="ml-0 md:ml-3 mt-5 md:mt-2 font-bold">
                Stats
              </h2>
              <div class="ml-0 md:ml-3 card">
                <div v-show="statsLoading">
                  <div class="py-16 text-center">
                    <v-spinner :show="statsLoading" />
                  </div>
                </div>
                <div v-show="!statsLoading">
                  <div class="text-right px-6">
                    <div
                      v-if="listOfCurrencyUsed.length > 1"
                      class="select-container no-border mr-2"
                    >
                      <select
                        v-model="statsFilterByCurrencyId"
                        @change="refreshStats"
                      >
                        <option
                          v-for="currency in listOfCurrencyUsed"
                          :key="currency.id"
                          :value="currency.id"
                        >
                          {{ currency.code }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="select-container no-border"
                      @change="refreshStats"
                    >
                      <select v-model="statsFilterByMonths">
                        <option value="1">
                          Last month
                        </option>
                        <option value="3">
                          Last 3 months
                        </option>
                        <option value="6">
                          Last 6 months
                        </option>
                        <option value="12">
                          Last 12 months
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="px-6 pt-5 flex flex-wrap">
                    <div class="w-1/2 md:w-3/12">
                      <div class="text-xs text-grey-darker">
                        New Inquiries
                      </div>
                      <div class="text-blue mt-1 text-base">
                        {{ newInquries }}
                      </div>
                    </div>
                    <div class="w-1/2 md:w-4/12">
                      <div class="text-xs text-grey-darker">
                        Accepted Proposals
                      </div>
                      <div class="text-blue mt-1 text-base">
                        {{ acceptedProposals }}
                      </div>
                    </div>
                    <div class="hidden md:block md:w-2/12">
                      <div class="text-xs text-grey-darker">
                        Booking rate
                      </div>
                      <div class="text-blue mt-1 text-base">
                        {{ bookingRate }}%
                      </div>
                    </div>
                    <div class="w-3/12" />
                  </div>
                  <div class="px-6 py-5 flex flex-wrap">
                    <div class="w-1/2 md:w-3/12">
                      <div class="text-xs text-grey-darker">
                        Invoices paid
                      </div>
                      <div class="text-blue mt-1 text-base">
                        {{ invoicesPaid }}
                      </div>
                    </div>
                    <div class="w-1/2 md:w-4/12">
                      <div class="hidden md:block">
                        <div class="text-xs text-grey-darker">
                          Unpaid invoices
                        </div>
                        <div class="text-blue mt-1 text-base">
                          {{ invoicesUnpaid }}
                        </div>
                      </div>
                      <div class="block md:hidden">
                        <div class="text-xs text-grey-darker">
                          Booking rate
                        </div>
                        <div class="text-blue mt-1 text-base">
                          {{ bookingRate }}%
                        </div>
                      </div>
                    </div>
                    <div class="block md:hidden w-full mt-5">
                      <div class="text-xs text-grey-darker">
                        Unpaid invoices
                      </div>
                      <div class="text-blue mt-1 text-base">
                        {{ invoicesUnpaid }}
                      </div>
                    </div>
                    <div class="pt-5 md:pt-0 w-1/2 md:w-2/12">
                      <div class="text-xs text-grey-darker">
                        Total revenue
                      </div>
                      <div class="text-blue mt-1 text-base">
                        <vue-numeric
                          v-model="totalRevenue"
                          :currency-symbol-position="(statsFilterByCurrency.sign_after_price) ? 'suffix' : 'prefix'"
                          :precision="2"
                          class="small-right"
                          :currency="statsFilterByCurrency.sign"
                          :minus="false"
                          :decimal-separator="statsFilterByCurrency.decimal_separator"
                          :thousand-separator="statsFilterByCurrency.thousand_separator"
                          :min="0"
                          read-only
                        />
                      </div>
                    </div>
                    <div class="pt-5 md:pt-0 w-1/2 md:w-3/12">
                      <div class="md:float-right">
                        <div class="text-xs text-grey-darker">
                          Total outstanding
                        </div>
                        <div class="text-blue mt-1 text-base">
                          <vue-numeric
                            v-model="totalOutstanding"
                            :currency-symbol-position="(statsFilterByCurrency.sign_after_price) ? 'suffix' : 'prefix'"
                            :precision="2"
                            class="small-right"
                            :currency="statsFilterByCurrency.sign"
                            :minus="false"
                            :decimal-separator="statsFilterByCurrency.decimal_separator"
                            :thousand-separator="statsFilterByCurrency.thousand_separator"
                            :min="0"
                            read-only
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ml-0 md:ml-3 mt-5 card">
                <div v-show="invoiceChartLoading">
                  <div class="py-32 text-center">
                    <v-spinner :show="invoiceChartLoading" />
                  </div>
                </div>
                <div v-show="!statsLoading">
                  <div class="flex items-start justify-between px-6">
                    <div class="flex pt-3 ">
                      <div class="flex items-center mr-3">
                        <div class="mr-2">
                          <div style="backgroundColor:#b3bee5; width:14px; height:14px; border-radius: 2px;" />
                        </div>
                        <div class="text-grey-darker text-xs">
                          Open
                        </div>
                      </div>
                      <div class="flex items-center">
                        <div class="mr-2">
                          <div style="backgroundColor:#666f92; width:14px; height:14px; border-radius: 2px;" />
                        </div>
                        <div class="text-grey-darker text-xs">
                          Paid
                        </div>
                      </div>
                    </div>
                    <div class="text-right">
                      <div
                        v-if="listOfCurrencyUsed.length > 1"
                        class="select-container no-border md:mr-2"
                      >
                        <select
                          v-model="invoiceStatsFilterByCurrencyId"
                          @change="refreshInvoiceChart"
                        >
                          <option
                            v-for="currency in listOfCurrencyUsed"
                            :key="currency.id"
                            :value="currency.id"
                          >
                            {{ currency.code }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="select-container no-border"
                        :class="{ '-mt-4 md:mt-0': listOfCurrencyUsed.length > 1}"
                        @change="refreshInvoiceChart"
                      >
                        <select v-model="invoiceStatsFilterByYear">
                          <option
                            v-for="year in invoiceYears"
                            :key="year"
                            :value="year"
                          >
                            Invoices issued in {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    class="pl-4 pr-8"
                    style="min-height:400px;"
                  >
                    <charts
                      ref="chart"
                      :options="chartOptions"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, addDays} from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import filter from 'lodash.filter'
import { formatNumeral, logException } from '@/helpers'
import { createCancelSource } from '@/http'
import auth from '@/auth'
import { getTasks } from '../api/Tasks'
import DueBadge from '../components/DueBadge'
import ActionProgress from '../components/ActionProgress'
import { getWorkflows } from '../api/Workflows'

let cancelSource

export default {
  name: 'Dashboard',
  components: { ActionProgress, DueBadge },
  data(){
    return {
      today: new Date(),
      next2Week: new Date(),
      logo: { path:'', width:0, height:0 },
      testProjectId: null,
      testInvoiceId: null,
      uploadError: false,
      uploadLoading: false,
      activityPage: 1,
      activityPerPage: 12,
      projectDateError: false,
      multiDays: false,
      activityLoadMore:false,
      activityLoadMoreLoading:false,
      activityLoading: true,
      tasksLoading: true,
      statsLoading: true,
      invoiceChartLoading: true,
      viewFilter: 'active',
      progress: 'done',
      paymentInfo: '',
      hasError: false,
      isNewContact: true,
      selectedContact: null,
      currencies: null,
      contact: '',
      tag: '',
      tags: [],
      autocompleteTagItems: [],
      projectName: '',
      projectDate: null,
      projectEndDate: null,
      projectCurrency: null,
      projectLanguage: 'en',
      fullName: '',
      email: '',
      groupedTasks: [],
      emailError: false,
      user: auth.user(),
      form: {},
      timezone: '',
      multiCurrencies: 0,
      languageContent: {
        content: 'The language to display on the client view for your proposals and invoices.'
      },
      workflowContent: {
        content: 'The workflow used for automated actions and tasks.'
      },
      activities: {},
      listOfCurrencyUsed: {},
      statsFilterByMonths: 6,
      statsFilterByCurrencyId: 1,
      invoiceStatsFilterByCurrencyId: 1,
      invoiceYears: [this.getCurrentYear() - 2, this.getCurrentYear() - 1, this.getCurrentYear()],
      statsFilterByCurrency: {},
      invoiceStatsFilterByCurrency: {},
      invoiceStatsFilterByYear: this.getCurrentYear(),
      newInquries:0,
      acceptedProposals:0,
      invoicesPaid:0,
      invoicesUnpaid:0,
      bookingRate:0,
      totalRevenue:0,
      totalOutstanding:0,
      chartOptions: {
        legend: {
          enabled: false
        },
        colors: ['#b3bee5', '#666f92'],
        yAxis: {
          title: null,
          min: 0,
          lineColor: '#ccc',
          stackLabels: {
              enabled: false,
              style: {
                  fontWeight: 'bold',
                  color: {
                      stops: [
                          [0, '#fff'],
                          [1, '#ccc']
                      ]
                  }
              }
          }
        },
        xAxis: {
          width: '96%',
          categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
          ],
          crosshair: true
        },
        credits: {
          enabled: false
        },
        chart: {
          backgroundColor: '#fff',
          borderColor: null,
          type: 'column',
          height: '400px',
          marginTop: 20,
        },
        title: null,
        subtitle: {
          enabled: false,
          align: 'right',
          margin: 20,
          floating: true,
          style: {
            display: 'none',
          },
          text: ''
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            pointPadding: 0,
            groupPadding: 0.1,
            dataLabels: {
                enabled: false
            }
          },
          series: {
            borderWidth: 0,
            marker: {
              enabled: false
            },
            label: {
                connectorAllowed: false
            }
          }
        },
        tooltip: {
          useHTML: true,
          formatter(){
              return `
                <div class="highcharts-tooltip">
                  <div class="mt-1">
                    <span class="value">${formatNumeral(this.y, this.series.chart.userOptions.currency.code)}</span>
                  </div>
                </div>
              `
          }
        },
        series: [{
        name: 'Open',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },{
        name: 'Paid',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
      },
      selectedWorkflow: null,
      workflows: []
    }
  },
  computed: {
    createProjectLanguages() {
      const languages = filter(this.user.companySettings.locales, (language => language.isActive))

      return languages
    }
  },
  watch: {
    'tag': 'initItems',
  },
  created(){
    if(this.user.company === undefined) {
      this.$router.push('/register/company')
    }
  },
  async mounted() {

    this.today = format(new Date(), 'MMM D, YYYY')
    this.next2Week = format(addDays(new Date(), 14), 'MMM D, YYYY')

    this.timezone = this.user.companySettings.timezone
    this.projectCurrency = this.user.companySettings.currency.id
    this.multiCurrencies = parseInt(this.user.companySettings.multiCurrencies)

    this.logo.path = this.user.company.logo
    this.refreshLogo()

    this.paymentInfo = this.user.companySettings.paymentInfo

    try {
      const { data } = await this.$api.get('currency').list()
      this.currencies = data.currencies
    } catch(e){
      this.$toasted.global.api_error(e)
      logException(e)
    }

    await this.getData()

    // try {
    //   const { data } = await this.$api.get('invoice').count(this.user.me.company.id)
    //   this.progress = (data.hasInvoiceAlready) ? 'done' : 'brand'
    // } catch(e){
    //   logException(e)
    // }

    // if(this.progress != 'done'){
    //   await this.refresh()
    // }
  },
  methods: {
    async loadWorkflows () {
      const res = this.handleErrors(await getWorkflows())
      if (!res) return
      this.workflows = res.data.data.sort((a, b) => a.name > b.name ? 1 : -1)
    },
    groupByDueDate (items) {
      const result = new Map()
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      items.filter(i => new Date(i.due_date) >= today)
        .filter(i => i.project)
        .sort((a, b) => a.due_date > b.due_date ? 1 : -1)
        .splice(0, 5)
        .forEach(item => {
          const date = format(new Date(item.due_date), 'YYYY-MM-DD')
          if (!result.has(date)) {
            result.set(date, [])
          }
          result.get(date).push(item)
      })

      return new Map([...result].sort())
    },
    refreshLogo(){
      let that = this
      let image = new Image()
      image.src = this.logo.path
      image.onload = function () {
        that.logo.width = this.width
        that.logo.height = this.height
      }
    },
    async markAsComplete(item){
      this.$toasted.clear()
      let postData = {
        is_completed: item.is_completed,
        id: item.id,
        project_id: (item.project_id) ? item.project_id : null,
        name: item.name,
        due_date: item.due_date,
      }

      try {
        await this.$api.get('tasks').update(item.id, postData)

        if(item.is_completed){
          this.$toasted.global.general_success({
            message : 'Your task has been marked as completed.'
          })
        } else {
          this.$toasted.global.general_success({
            message : 'Your task has been marked as incomplete.'
          })
        }

        await this.fetchTasks()
      } catch(e){
        logException(e)
      }
    },
    goTestInvoice(){

      this.$gtag.event('onboarding', {
        'event_category': 'company',
        'event_label': 'invoice',
        'value': 3,
      })

      this.$router.push(`/projects/${this.testProjectId}/invoice/${this.testInvoiceId}`)
    },
    async saveBankDetails(){

      this.$toasted.clear()
      if(this.paymentInfo == ''){
        this.$toasted.global.general_error({
          message : 'Please enter your company id, bank details, etc..'
        })
        return false
      }
      const postData = {
        paymentInfo: this.paymentInfo,
      }

      try {
        await this.$store.dispatch('company/patchPaymentInfo', postData)
        await this.$store.dispatch('company/refreshCompanyDetails')
        auth.refreshUser()

        await this.createInvoiceSample()
        this.progress = 'finish'

        this.$gtag.event('onboarding', {
          'event_category': 'company',
          'event_label': 'bank',
          'value': 3,
        })

      } catch(e) {
        this.$toasted.global.api_error(e)
      }

    },
    async createInvoiceSample(){
      try {
        const { data } = await this.$api.get('invoice').createTestInvoice(this.user.me.company.id)
        this.testInvoiceId = data.invoice.id
        this.testProjectId = data.project.id
      } catch(e){
        logException(e)
      }
    },
    async fileUpload(){

      let that = this
      let _URL = window.URL || window.webkitURL
      let file = this.$refs.file.files[0]
      this.uploadError = false
      this.uploadLoading = true

      if (file.size > 5000 * 1024 || (file.type != 'image/jpeg' && file.type != 'image/jpg' && file.type != 'image/png')) {
        this.uploadError = true
        this.uploadLoading = false
        that.$toasted.global.general_error({
          message : 'Please check your image for valid extension (jpg / png), and file size (within 5mb).'
        })
        return
      }

      let img = new Image()
      img.onload = function () {
        let hasError = false
        if(this.width > this.height){
          if(this.width < 500){
            hasError = true
          }
        }
        if(this.width < this.height){
          if(this.height < 500){
            hasError = true
          }
        }
        if(this.width == this.height){
          if(this.width < 500 && this.height < 500){
            hasError = true
          }
        }
        if(hasError){
          that.uploadError = true
          that.uploadLoading = false
          that.$toasted.global.general_error({
            message : 'Please check your image for valid extension (jpg / png), and file size (within 5mb).'
          })
          return false
        }
      }

      img.src = _URL.createObjectURL(file)

      let formData = new FormData()
      formData.append('image', file)

      try {
        await this.$store.dispatch('company/uploadLogo', formData)
        await this.refresh()
        this.progress = 'info'
        this.uploadLoading = false

        this.$gtag.event('onboarding', {
          'event_category': 'company',
          'event_label': 'logo',
          'value': 1,
        })

      } catch(e) {
        //this.$toasted.global.api_error(e)
      }
    },
    async refresh() {
        try {
          await this.$store.dispatch('company/refreshCompanyDetails')

          const company = this.$store.getters['company/details']

          this.logo.path = company.logo
          this.refreshLogo()

          this.paymentInfo = company.paymentInfo

          if(this.logo.path){
            this.progress = 'info'
          }
          if(this.paymentInfo && this.progress != 'finish'){
            this.progress = 'finish'
            await this.createInvoiceSample()
          }

          auth.refreshUser()

        } catch(e) {
          this.$toasted.global.api_error(e)
        }
      },
    async showMoreActivities(){
      cancelSource = createCancelSource()
      this.activityPage++
      this.activityLoadMoreLoading = true

      try {
        const { data } = await this.$api.get('dashboard').activities(this.user.me.company.id, {
          cancelToken: cancelSource.token,
          page: this.activityPage,
          take: this.activityPerPage
        })

        // data.activities.forEach(item => {
        //   this.activities.push(item)
        // })
        for (const key of Object.keys(data.activities)) {
           this.activities.push(data.activities[key])
        }

        if(data.totalActivities <= this.activities.length){
          this.activityLoadMore = false
        }

        this.activityLoadMoreLoading = false

      } catch(e){
        logException(e)
      }
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
       return true
      } else {
       return false
      }
    },
    async markAllAsSeen(){
      this.activityLoading = true
      try {
        const { data } = await this.$api.get('dashboard').markAllAsSeen(this.user.me.company.id)
        this.activities = data.activities

        if(data.totalActivities > this.activityPerPage){
          this.activityLoadMore = true
        }
        this.activityLoading = false
        this.$toasted.global.general_success({
          message : 'Marked all activities as seen.'
        })
      } catch(e){
        logException(e)
      }
    },
    markAsSeen(activity){
      activity.has_seen = 1

      if(activity.type === 'questionnaire') {
        this.$router.push(`/${activity.type}/preview/${activity.model_id}`)
      }

      if(activity.type === 'lead') {
        this.$router.push(`/projects/${activity.project_id}`)
      }

      if(activity.type == 'proposal' || activity.type == 'invoice'){
        this.$router.push(`/${activity.type}s/preview/${activity.model_id}`)
      }

      if(activity.type == 'task'){
        this.$router.push('/tasks')
      }
    },
    goDetail(data){
      this.$router.push('/projects/'+data.row.id)
    },
    async checkEmail(){
      this.emailError = false
      try {
        const { data } = await this.$api.get('contacts').checkEmail({ contactEmail: this.email })

        if(data.contact){
          this.emailError = true
          this.errors.add({
            field: 'email',
            msg: 'This email address is already in use.'
          })
        }
      } catch(e){
        logException(e)
      }
    },
    changeFilter(target){
        this.$router.push({name: 'Projects', query: {  filter: this.viewFilter } })
        this.$refs.mobileDatatable.refresh()
    },
    toggleFilter(target){
      if(this.viewFilter != target){
        this.viewFilter = target
        this.$router.push({name: 'Projects', query: {  filter: target } })
        this.$refs.datatable.refresh()
      }
    },
    showCreateProjectModal(){
      this.$modal.show('createProject')
    },
    updateTags(newTags) {
      this.autocompleteTagItems = []
      this.tags = newTags
    },
    toggleContact(){
      this.selectedContact = null
      if(this.isNewContact) {
        this.isNewContact = false
      } else {
        this.isNewContact = true
      }
    },
    async searchContact(input) {
      if (input.length < 3) { return [] }

      this.selectedContact = null
      try {
        const { data } = await this.$api.get('contacts').search(input)
        const { results } = data
        return results
      } catch(e){
        logException(e)
      }
    },
    getContactResult(result){
      return result.first_name+' '+result.last_name
    },
    searchContactClick(result) {
      this.selectedContact = result
    },
    createProject(){
      this.hasError = false
      this.$validator.validate().then(result => {
        // if this.multiDays is true, then projectEndDate is required
        if (this.multiDays && !this.projectEndDate) {
          this.hasError = true
          this.projectDateError = 'End date is required when extended event is checked.'
          return false
        }

        // if this.multiDays is true, then projectEndDate cannot be before projectDate
        if (this.multiDays && this.projectEndDate && this.projectEndDate < this.projectDate) {
          this.hasError = true
          this.projectDateError = 'End date cannot be before start date.'
          return false
        }

        if(!this.emailError && !this.isNewContact && !this.selectedContact) {
          this.hasError = true
          return false
        }
        if(result){
          this.create()
        }
      })
    },
    async create(){
      cancelSource = createCancelSource()

      let date = (this.projectDate) ? format(this.projectDate, 'YYYY-MM-DD') : null
      if(date){
        try {
          const { data } = await this.$api.get('projects').checkDate({ date: date })
          if(data.status == 'exist'){
            const canGo = await this.$alert.confirm({
              title: 'Oops, it looks like another project is already on this date.',
              text: 'Are you sure you want to continue?',
            })
            if(canGo) {
              await this.saveProject()
            }
          } else {
             await this.saveProject()
          }
        } catch(e){
          logException(e)
        }
      } else {
        await this.saveProject()
      }
    },
    async saveProject(){
      cancelSource = createCancelSource()

      let date = (this.projectDate) ? format(this.projectDate, 'YYYY-MM-DD') : null
      let endDate = (this.projectEndDate && this.multiDays) ? format(this.projectEndDate, 'YYYY-MM-DD') : null

      const input = {
         isNewContact: this.isNewContact,
         contact: this.selectedContact,
         projectName: this.projectName,
         projectDate: date,
         projectEndDate: endDate,
         projectCurrency: this.projectCurrency,
         projectLanguage: this.projectLanguage,
         contactFullName: this.fullName,
         contactEmail: this.email,
         tags: this.tags,
         workflowId: this.selectedWorkflow,
       }

      try {
        const { data } = await this.$api.get('projects').create(input, {
          cancelToken: cancelSource.token
        })

        const postData = {
          entryType: 'add_first_project',
        }

        try {
          await this.$store.dispatch('company/gamification', postData)
        } catch(e) {
          this.$toasted.global.api_error(e)
        }

        if(data.project.id){
          this.$router.push('/projects/'+data.project.id)
        }

      } catch(e){
        logException(e)
      }
    },
    async initItems() {
      if (this.tag.length === 0) return

      cancelSource = createCancelSource()

      try {
        const { data } = await this.$api.get('tags').search(this.tag, {
          cancelToken: cancelSource.token
        })

        const { tags } = data
        this.autocompleteTagItems = tags.map(item => {
          const name = JSON.parse(item.name)
          return { text: name.en }
        })

      } catch(e){
        logException(e)
      }
    },
    async fetchTasks(){
      const res = this.handleErrors(await getTasks(this.filter, this.search))
      if (!res) return

      this.groupedTasks = this.groupByDueDate(res.data)
      this.tasksLoading = false
    },
    async fetchActivities(){
      cancelSource = createCancelSource()
      try {
        const { data } = await this.$api.get('dashboard').activities(this.user.me.company.id, {
            cancelToken: cancelSource.token,
            page: this.activityPage,
            take: this.activityPerPage
          })
        this.activities = data.activities
        if(data.totalActivities > 2){
          this.activityLoadMore = true
        }
        this.activityLoading = false

      } catch(e){
        logException(e)
      }
    },
    async fetchStats(){

        cancelSource = createCancelSource()
        const queryStats = {
          currencyId: this.statsFilterByCurrencyId,
          statsFilterByMonths: this.statsFilterByMonths,
          cancelToken: cancelSource.token
        }
        try {
          const { data } = await this.$api.get('dashboard').stats(this.user.me.company.id, queryStats)
          this.newInquries = data.newInquries
          this.invoicesPaid = data.invoicesPaid
          this.invoicesUnpaid = data.invoicesUnpaid
          this.acceptedProposals = data.acceptedProposals
          this.totalOutstanding = data.totalOutstanding
          this.totalRevenue = data.totalRevenue
          this.bookingRate = data.bookingRate

          // if(data.invoiceYears !== undefined){
          //   this.invoiceYears = data.invoiceYears
          //   let latestYear = Math.max.apply(null,Object.keys(this.invoiceYears))
          //   this.invoiceStatsFilterByYear = latestYear
          // } else {
            this.invoiceStatsFilterByYear = this.getCurrentYear()
          // }

          this.statsLoading = false
        } catch(e){
          logException(e)
        }
    },
    async fetchInvoiceChart(){

        const queryStats = {
          currencyId: this.invoiceStatsFilterByCurrencyId,
          invoiceStatsFilterByYear: this.invoiceStatsFilterByYear,
          cancelToken: cancelSource.token
        }

        try {
          const { data } = await this.$api.get('dashboard').invoices(this.user.me.company.id, queryStats)

          this.chartOptions.series[0].data = data.open
          this.chartOptions.series[1].data = data.paid
          this.chartOptions.currency = this.invoiceStatsFilterByCurrency

          this.invoiceChartLoading = false
        } catch(e){
          logException(e)
        }
    },
    async refreshStats(){
      for (var key in this.listOfCurrencyUsed) {
        if(this.statsFilterByCurrencyId == this.listOfCurrencyUsed[key]['id']){
          this.statsFilterByCurrency = this.listOfCurrencyUsed[key]
        }
      }
      await this.fetchStats()
    },
    async refreshInvoiceChart(){

      for (var key in this.listOfCurrencyUsed) {
        if(this.invoiceStatsFilterByCurrencyId == this.listOfCurrencyUsed[key]['id']){
          this.invoiceStatsFilterByCurrency = this.listOfCurrencyUsed[key]
        }
      }

      await this.fetchInvoiceChart()
    },
    async getData(){

      this.statsFilterByCurrencyId = this.user.companySettings.currency.id
      this.invoiceStatsFilterByCurrencyId = this.user.companySettings.currency.id
      this.statsFilterByCurrency = this.user.companySettings.currency
      this.invoiceStatsFilterByCurrency = this.user.companySettings.currency

      try {
        const { data } = await this.$api.get('dashboard').currencies(this.user.me.company.id)
        this.listOfCurrencyUsed = data.currencies
      } catch(e){
        logException(e)
      }

      this.fetchActivities()
      this.fetchTasks()
      this.fetchStats()
      this.fetchInvoiceChart()
      this.loadWorkflows()
    },
    getCurrentYear() {
      return new Date().getFullYear()
    }
  },
}
</script>

<style scoped>
  .modal-content-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 5px);
  }
</style>
